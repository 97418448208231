import { 
  api,
  createCrudService} from '@cargill/shared'

const service = createCrudService('/api/weeklySummaryPlanAllocationReport', api, { importLogUrl: '/api/importLog' })

service.exportCsvToZip = async (filter) => {
  const baseUrl = '/api/weeklySummaryPlanAllocationReport/exportCsvToZip'
  const url = filter ? `${baseUrl}?${filter}` : `${baseUrl}`
  try {
    const response = await api.get(url, {
      responseType: 'blob'
    })
    return response?.data
  } catch (e) {
    this.handleError(e)
    throw e
  }
}

export default service
