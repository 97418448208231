<template>
  <div v-if="metadata != null">
    <cargill-crud-meta-view
      :service="crudService" 
      :metadata="metadata"
      @gridReady="onGridReady"
      translatePrefix="application.pages" />
  </div>
</template>

<script>
import { CargillCrudMetaView, helpers } from '@cargill/shared'
import service from '../../api/weeklySummaryPlanAllocationReportService'
import FileDownload from 'js-file-download'
      
export default {
  components: { CargillCrudMetaView },
  data() {
    return {
      metadata: null,
      crudService: service    }
  },
  methods: {
    async getMeta() {
      const meta = await service.getMetaWithValidation()
      return meta
    },
    onGridReady() {
      const crudActionsToolbar = helpers.findComponent(
        this,
        'BcCrudActionsToolbar'
      )
      const importAction = crudActionsToolbar.actions.find(
        (action) => action.titleKey == 'core.misc.import'
      )

      if (importAction) {
        importAction.canShow = true
        importAction.titleKey = 'core.misc.exportCsvToZip'
        importAction.onClick = async () => {
          const fileName = this.$t('application.pages.weeklySummaryPlanAllocationReport.title')
          const data =
            await service.exportCsvToZip(
              service.lastAppliedFilter
            )
          FileDownload(data, `${fileName}.zip`)
        }
        importAction.faIcon = 'fa-file-csv'
        crudActionsToolbar.$forceUpdate()
      }
    }
  },
  created() {
    this.getMeta().then((meta) => {
      this.metadata = meta
    })
  }
}
</script>
